<!--
 * @Description: 拆单弹框
 * @Author: ChenXueLin
 * @Date: 2021-08-26 13:54:17
 * @LastEditTime: 2022-07-01 09:28:53
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="拆单"
    :visible="splieOrderDialog"
    width="800px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="splie-order-dialog"
  >
    <!--  :before-close="handleCloseScene" -->
    <div class="tip-box">
      <i class="e6-icon-tips_line"></i>
      <div class="tip-text">
        使用说明：仅未指派且终端要安装的设备数>=2件才可使用拆单，勾选需要拆分的设备，提交确认后，场景名称会拆成两个任务项，可分别指派给不同的工程师。已进行过拆单的场景名称可再次拆单。
      </div>
    </div>
    <div class="table-box">
      <div class="install-info">
        <div class="info">
          <span>场景名称</span>
          <span>粤B9V377</span>
        </div>
        <div class="info">
          <span>场景类型</span>
          <span>车辆</span>
        </div>
      </div>
      <el-table border height="300px" :data="installData" highlight-current-row>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in installDataColumn"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <div v-if="column.fieldName === 'id'">
              <e6-vr-select
                :data="typeList"
                placeholder="任务单二级分类"
                title="任务单二级分类"
                clearable
              ></e6-vr-select>
            </div>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      installDataColumn: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "车牌颜色/地址（非必填）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      installData: [],
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      imageUrl: ""
    };
  },
  props: {
    splieOrderDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    //关闭弹框
    // handleCloseScene() {
    //   this.$emit("handleCloseScene");
    // },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.splie-order-dialog {
  // .el-dialog__header {
  //   margin-bottom: 10px;
  // }
  .tip-box {
    width: 100%;
    display: flex;
    padding-left: 15px;
    align-items: flex-start;
    padding-right: 15px;
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-top: 20px;
    [class^="e6-icon"] {
      font-size: 18px;
    }
    .tip-text {
      color: #c0bfc4;
      margin-left: 5px;
      line-height: 20px;
    }
  }
  .table-box {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    .install-info {
      display: flex;
      margin-bottom: 15px;
      .info {
        margin-right: 40px;
        > span {
          &:nth-child(1) {
            color: #a9b5cd;
            margin-left: 5px;
          }
          &:nth-child(2) {
            color: #535355;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
